import Dress1 from '../images/Bridal/WhiteGoldWorn.jpg'
import Dress2 from '../images/Bridal/BridalCouple.jpg'
import Dress3 from '../images/Bridal/WhiteGoldTop.jpg'
import Dress4 from '../images/Bridal/WhiteGoldRear.jpg'
import Dress5 from '../images/Bridal/WhiteGoldSlantLeft.jpg'
import Dress6 from '../images/Bridal/WhiteGoldSlantRight.jpg'
import Dress7 from '../images/Bridal/WhiteGoldApplique.jpg'
import Dress8 from '../images/Bridal/AppliqueDressFront.jpg'
import Dress9 from '../images/Bridal/AppliqueDressRear.jpg'



const BridalDB = [
    Dress1,
    Dress2,
    Dress3,
    Dress4,
    Dress5, 
    Dress6,
    Dress7,
    Dress8,
    Dress9

];

export default BridalDB