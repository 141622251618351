import Pic1 from '../images/SilverPose.png'
import Pic2 from '../images/PinkPose.png'

const SliderDB = [
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
    {
        Img1: Pic1,
        Img2: Pic2
    },
]

export default SliderDB