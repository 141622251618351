import video1 from '../video/WhiteGoldDisplay.mp4'
import video2 from '../video/WhiteGoldCouple.mp4'
import video3 from '../video/WhiteGold.mp4'
import video4 from '../video/BlueDress.mp4'
import video5 from '../video/WeddingApplique.mp4'
import video6 from '../video/WeddingAppliqueBack2.mp4'
import video7 from '../video/Green.mp4'


const VideoDB = [
    video1,
    video2,
    video3,
    video4,
    video5,
    video6,
    video7
];

export default VideoDB